<!--
 * 
 *邀请有奖 (换9.9低价换购资格)
-->

<template>
  <div>
    <div id="invite-award">
      <!-- 固定顶部 -->
      <div class="fixedBox">
        <!-- tabs -->
        <div class="tabs">
          <div
            v-for="item in tabs"
            :key="item.value"
            :class="item.value == activeTab ? 'activeTab' : ''"
            @click="inviteTabs(item.value)"
          >
            {{ item.name }}

            <div v-if="item.value == activeTab" class="tabLine"></div>
          </div>
        </div>

        <div v-if="activeTab == 2" class="rules">
          <div>
            邀请榜前10名，可获得一份空投
            <van-icon name="warning-o" @click="openPop" />
          </div>
        </div>
      </div>

      <!-- 活动信息 -->
      <div v-if="activeTab == 1" class="activityBox">
        <div style="font-size: 0">
          <img :src="activityImg" />
        </div>

        <div class="activityTitle">邀请好友 抢9.9元低价购资格</div>
        <div class="activityDesc">
          <p>
            1、成功邀请3人（注册并实名认证），即可获得1次9.9元购买《仙武帝尊》系列数字藏品资格，限量100名！
          </p>
          <p>2、邀请榜前10，均可获得看漫数字藏品盲盒空投1份！</p>
        </div>

        <div class="activityTip">
          <div>活动截止《仙武帝尊》系列数字藏品发售前</div>
          <div>本活动最终解释权归看漫App所有</div>
        </div>
      </div>

      <!-- 邀请榜单 -->
      <div v-if="activeTab == 2" class="rankBox">
        <div class="rankList">
          <div class="rankItem" v-for="(item, index) in topList" :key="index">
            <div class="userInfo">
              <div class="number" :style="index < 3 ? 'background: #3CDBE8;color:black;' : ''">
                {{ index + 1 }}
              </div>
              <!-- <div class="headImg">
								<img src="../imgs/comm/pic_avatar_login.png" alt="" srcset="">
							</div> -->
              <div class="name">
                <div :style="index < 3 ? 'color:#3CDBE8' : ''">{{ item.user_name }}</div>
                <!-- <div>ID：{{ item.user_id }}</div> -->
              </div>
            </div>

            <div>
              邀请<span class="inviteNum">{{ item.invite_total }}</span
              >人
            </div>
          </div>

          <!-- 我的排名 -->
          <div v-if="myTop" class="rankItem">
            <div class="userInfo">
              <div class="number myNumber">{{ myTopNum(myTop.user_id) }}</div>
              <!-- <div class="headImg">
								<img src="../imgs/comm/pic_avatar_login.png" alt="" srcset="">
							</div> -->
              <div class="name">
                <div>我</div>
                <!-- <div>ID：{{ myTop.user_id }}</div> -->
              </div>
            </div>

            <div>
              邀请<span class="inviteNum">{{ myTop.invite_total }}</span
              >人
            </div>
          </div>

          <div class="rankTip">榜单仅显示前10名</div>
        </div>
      </div>

      <!-- 底部遮罩 -->
      <div class="footer">
        <shareBtn
          v-if="activityStatus == 1"
          :activityId="activityId"
          :posterBg="posterBg"
          :startTime="startTime"
        />
        <!-- <div class="posterBtn" v-if="diffTime(startTime) < 0" @click="qrcodeDom">
        生成专属邀请海报
      </div> -->
        <div v-if="activityStatus == 2" class="posterBtn disPosterBtn">
          本期活动已结束，敬请期待下期
        </div>

        <div
          v-if="activityStatus == 3"
          class="posterBtn"
          style="font-size: 16px"
          @click="toWinnerList"
        >
          本期活动已结束，查看获奖名单
        </div>
        <div class="toRecord" @click="toInviteRecord">邀请记录<van-icon name="arrow" /></div>
      </div>

      <van-popup class="vantPop" v-model:show="popShow">
        <div class="popup">
          <div class="content">
            <div>1、数字藏品开售前，邀请榜上前10名，可获得空投一份。</div>
            <div>2、当邀请人数相同时，最早邀请到当前人数的用户排名靠前。</div>
          </div>
          <div class="btn">
            <div @click="popShow = false">知道了</div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, toRefs, inject, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getTop, getactivityinfo } from '@/service/invite';
import { nftUtils } from '@/utils';
import moment from 'moment';
import shareBtn from '@/components/inviteSharePoster/inviteSharePoster';
export default {
  name: 'invite',
  components: {
    shareBtn
  },
  setup() {
    const toastFn = inject('$toast');
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      activityId: 1,
      tabs: [
        {
          value: 1,
          name: '活动信息'
        },
        {
          value: 2,
          name: '邀请榜单'
        }
      ],
      activeTab: 1,
      popShow: false,
      topList: [],
      myTop: null,
      myTopItem: null,
      activityImg: '',
      startTime: '',
      posterBg: require('../imgs/poster/posterBg0530.jpg'),
      activityStatus: null
    });

    const toInviteRecord = () => {
      router.push({
        path: '/inviteRecord',
        query: { activityStatus: state.activityStatus, activityId: state.activityId }
      });
      nftUtils.setcnzzRecordByBtn('邀请记录');
    };

    const toWinnerList = () => {
      router.push({
        path: '/winnerList',
        query: { activityId: state.activityId }
      });
      nftUtils.setcnzzRecordByBtn('获奖名单');
    };

    const getTopFn = () => {
      getTop({
        size: 10,
        act_id: state.activityId
      }).then(res => {
        console.log(res);
        if (res.status === 0) {
          state.topList = res.data.rank;
          state.myTop = res.data.user;
        } else {
        }
      });
    };

    const myTopNum = id => {
      let myTopNum = '-';
      for (let i in state.topList) {
        if (state.topList[i].user_id === id) {
          myTopNum = ++i;
        }
      }
      return myTopNum;
    };

    const inviteTabs = value => {
      state.activeTab = value;
      if (value == 1) {
        nftUtils.setcnzzRecordByBtn('活动信息');
      } else if (value == 2) {
        nftUtils.setcnzzRecordByBtn('邀请榜单');
      }
    };

    const openPop = () => {
      state.popShow = true;
      nftUtils.setcnzzRecordByBtn('邀请榜规则说明弹窗');
    };

    const getList = async () => {
      await store.dispatch('getHomeList');
      state.activityImg = store.state.homeList[0].image_bg;
      // state.startTime = store.state.homeList[0].start_time;
    };

    // 查活动信息获取活动开始时间用于判断底部按钮状态
    const getInfo = () => {
      getactivityinfo({
        activity_id: state.activityId
      }).then(res => {
        console.log('活动信息', res);
        state.startTime = res.data.start_time;
        state.activityStatus = res.data.status;
      });
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    onMounted(() => {
      getList();
      getTopFn();
      getInfo();
      nftUtils.setcnzzRecordByBtn('进入邀请有奖页面');
    });

    return {
      ...toRefs(state),
      getList,
      toInviteRecord,
      myTopNum,
      inviteTabs,
      openPop,
      getInfo,
      diffTime,
      toWinnerList
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}
#invite-award {
  .fixedBox {
    position: fixed;
    background: $bc;
    width: 100%;
    top: 0;
    left: 0;
  }

  .tabs {
    display: flex;
    justify-content: center;
    line-height: 40px;
    padding: 7px 0;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);

    .activeTab {
      color: white;
      font-size: 16px;
      font-weight: 500;
    }

    div {
      padding: 0 18px;
      position: relative;

      .tabLine {
        height: 3px;
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
      }
    }
  }

  .rules {
    background: #2f293f;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
  }

  .activityBox {
    padding: 64px 20px 134px 20px;
    text-align: center;
    color: #ffffff;

    img {
      width: 100%;
    }

    .activityTitle {
      font-size: 20px;
      font-weight: 500;
      padding-top: 24px;
      padding-bottom: 10px;
    }

    .activityDesc {
      font-size: 14px;
      font-weight: 400;
      text-align: left;

      p {
        margin: 10px 0 0 0;
      }
    }

    .activityTip {
      margin-top: 26px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.4);
      div {
        padding-top: 6px;
      }
    }
  }

  .rankBox {
    padding: 90px 20px 0 20px;
  }

  .rankList {
    padding-top: 8px;
    padding-bottom: 134px;

    .rankItem {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-weight: 400;

      .userInfo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .number {
        color: rgba(255, 255, 255, 1);
        width: 28px;
        height: 28px;
        background: rgb(54, 51, 64);
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        line-height: 28px;
        border-radius: 50%;
      }

      .myNumber {
        background: #fff;
        color: black;
      }

      .headImg {
        margin-left: 20px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 2px solid #3cdbe8;
          object-fit: cover;
        }
      }

      .name {
        margin-left: 16px;

        :first-child {
          font-size: 14px;
          font-weight: 600;
        }

        :last-child {
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .inviteNum {
        color: #3cdbe8;
      }
    }

    .rankTip {
      text-align: center;
      padding: 25px 0 0 0;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 134px;
    background: linear-gradient(180deg, rgba(28, 23, 42, 0) 0%, #000000 100%);

    .disPosterBtn {
      opacity: 0.6;
      font-size: 16px;
    }

    .toRecord {
      position: absolute;
      top: 92px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
  }

  .popup {
    width: 300px;
    background: #2f293f;
    border-radius: 8px 8px 8px 8px;
    text-align: left;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;

    .content {
      padding: 30px 16px;
      line-height: 26px;
    }

    .btn {
      div {
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        border-top: 1px solid #494359;
      }
    }
  }

  #testPost {
    position: relative;
    display: inline-block;
    .posterBg {
      width: 308px;
      height: 522px;
    }

    .qrcodeImg {
      width: 124px;
      height: 124px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 176px;
      border: 3px solid;
      border-image: linear-gradient(180deg, rgba(255, 233, 203, 1), rgba(141, 129, 118, 1)) 3 3;
    }
  }

  .poster {
    width: 308px;

    .saveTip {
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      color: #ffffff;
    }

    img {
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
#invite-award {
  .vantPop {
    background: transparent !important;
  }
  .posterBtn {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 252px;
    height: 44px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    font-weight: 500;
    color: #1c172a;
    line-height: 44px;
    text-align: center;
    opacity: 1;
  }
  .qrcodeImg {
    top: 352px;
  }
}
</style>
